<template>
  <div class="list-page">
    <list-layout
      ref="listLayout"
      :filter-form="filterForm"
      :thead="commonThead"
      :on-fetch="getList"
      :on-delete="handleDelete"
      @stats="getListdone"
      @command="handleOperation"
    >
      <template v-slot:filter>
        <list-filter :filterForm="filterForm" :uploadFilter="ok" />
      </template>
      <template slot="item_info_avatar" slot-scope="{ row }">
        <list-image
          fit="cover"
          :width="40"
          border-radius="50%"
          :src="row.info_avatar"
        />
      </template>
    </list-layout>
  </div>
</template>
<script>
import ListStatusText from '@/base/components/List/ListStatusText'
import ListLayout from '@/base/layout/ListLayout'
import listFilter from '../components/ListFilter'

import Reminder from '@/modules/common/components/Reminder.vue'
import ListImage from '@/base/components/List/ListImage'

import { opinionDataIndex, deleteRow } from '../api/index'
export default {
  components: {
    listFilter,
    ListLayout,
    ListStatusText,
    Reminder,
    ListImage,
  },
  data() {
    return {
      tabCommonArr: [{ label: '全部', name: 'all' }],
      // 公共版数据表格
      commonThead: [
        { type: 'selection' },
        { label: '标题', prop: 'title' },
        { label: '意见收集数量', prop: 'opinions_count' },
        { label: '类型', prop: 'data_type_text' },
        {
          label: '创建时间',
          prop: 'create_time',
          sortable: true,
        },
        {
          label: '操作',
          type: 'operation',
          width: 200,
          operations: [
            { command: 'check', text: '查看' },
            { command: 'delete' },
          ],
        },
      ],
      // 筛选对象
      filterForm: {
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        title: '',
        page_size: 15, //每页多少条数据
        // 类型
        data_type: -1,
      },
      loading: false,
      // 是否显示提示
      showReminder: false,
    }
  },
  methods: {
    handleOperation(e) {
      switch (e.command) {
        case 'check': {
          console.log(e)
          this.$router.push({
            name: 'OpinionDetail',
            params: {
              id: e.row.id,
            },
          })
          break
        }
        default:
      }
    },
    getList(data) {
      this.loading = true
      return opinionDataIndex(data)
    },
    // 数据删除
    handleDelete(idList, forever = false) {
      console.log(idList)
      deleteRow({ id: idList })
        .then((res) => {
          this.$message.success(res.msg)
          this.refreshList()
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 获取到的数据
    getListdone(res) {
      console.log(res)
      this.loading = false
    },
    // 筛选回调
    ok(e) {
      this.filterForm = { ...this.filterForm, ...e, page: 1 }
      this.$refs.listLayout.getList(this.filterForm)
    },
    // 刷新列表
    refreshList() {
      this.$refs.listLayout.getList()
    },
  },

  computed: {
    tenantVersion() {
      return this.$store.getters.tenant.version
    },
  },
  created() {
    console.log(this.tenantVersion)
  },
}
</script>

<style lang="scss" scoped></style>
