import api from "@/base/utils/request"

// 收集列表
export const opinionDataIndex = data => {
  return api({
    url: "/admin/opinion/opinionData/index",
    method: "post",
    data
  });
};
// 删除
export const deleteRow = data => {
  return api({
    url: "/admin/opinion/opinionData/delete",
    method: "post",
    data
  });
};

